import React, { useContext } from 'react';
// Libraries
import { Link } from 'react-router-dom';
// Contexts
import { SidebarContext } from '~/contexts/SidebarContext';
// Standalone Components
import { Container, Image } from './style';
// Utils
//import { GetBrandIcon } from '~/utils/SocialMeAssets';

export default function SidebarHeader() {
  const { collapsed } = useContext(SidebarContext);

  return (
    <Container>
      {collapsed ? (
        <div style={{ display: 'flex' }}>
          <Link to="/">
            <Image src={'/logo.png'} alt="..." height="24px" />
          </Link>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <Link to="/">
            <Image src={'/logo.png'} alt="..." height="80px" />
          </Link>
        </div>
      )}
    </Container>
  );
}
