import React, { useContext, useEffect, useState } from 'react';
// Libraries
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import ProfileDrawer from '~/pages/SIGAM/components/ProfileDrawer';
// Components
import Avatar from '~/components/avatar';
import Text from '~/components/typography/text';
import Divider from '~/components/divider';
import Space from '~/components/space';
import Drawer from '~/components/drawer';
// Environments
import System from '~/environments/System';
// Standalone Components
import { MenuContainer, MenuItem, Container } from './style';
// Utils
import { UserAvatar, UserName } from '~/utils/UserInformation';
import { GetModuleNameByHostname } from '~/utils/Hosts';

export default function HasUser({ withoutSidebar }) {
  const { Logout, user } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  const { isMobile } = useContext(ResponsiveContext);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    async function isAdmin() {
      if ('GOD' in user.permission) {
        setAdmin(true);
      }
    }
    if (user) {
      if (user.permission) {
        isAdmin();
      }
    }
  }, []);

  const menu = (
    <MenuContainer
      borderColor={theme.color.borderColor}
      backgroundColor={theme.color.backgroundSecondaryLight}
      marginTop={isMobile ? '10px' : '0px'}
      fontSize={isMobile ? '12pt' : '10pt'}
    >
      {admin && (
        <NavLink to="/sigam-admin">
          <MenuItem>
            <ToolOutlined style={{ marginRight: 5 }} />
            Administração IMPACTO
          </MenuItem>
        </NavLink>
      )}

      <Drawer
        Trigger={
          <MenuItem>
            <UserOutlined style={{ marginRight: 5 }} />
            Perfil
          </MenuItem>
        }
        DrawerContentComponent={ProfileDrawer}
        title="Perfil"
      />
      <Divider style={{ margin: '5px 0px' }} />
      <MenuItem
        onClick={() =>
          Logout(
            GetModuleNameByHostname() === 'localhost'
              ? 'http://localhost:3000'
              : `https://${System.hostname[GetModuleNameByHostname()]}`
          )
        }
      >
        <LogoutOutlined /> {translate.basic.logout.text}
      </MenuItem>
    </MenuContainer>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Container>
        <Space>
          <Avatar src={UserAvatar()} style={{ border: '2px solid white' }} />
          {!isMobile && (
            <>
              <Text style={{ color: withoutSidebar ? '#fff' : '' }}>
                {UserName()}
              </Text>
              <DownOutlined
                style={{ fontSize: '7pt', color: withoutSidebar ? '#fff' : '' }}
              />
            </>
          )}
        </Space>
      </Container>
    </Dropdown>
  );
}
