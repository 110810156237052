import React, { useEffect, useState } from 'react';
import htmlToPdfmake from 'html-to-pdfmake';
import { useParams } from 'react-router-dom';
// Utils
import { Page } from 'react-pdf';
import { PrinterOutlined, CheckOutlined } from '@ant-design/icons';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Button from '~/components/button';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DecodeURI, CapitalizeString } from '~/utils/Transformations';
import System from '~/environments/System';
import Loading from '~/components/loading/';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

function GetFieldValue(value) {
  switch (value) {
    case 'ACTIVE':
      return 'Ativo';
    case 'INACTIVE':
      return 'Inativo';
    case 'PUBLIC':
      return 'Público';
    case 'RESTRICTED':
      return 'Restrito';
    case 'CONFIDENTIAL':
      return 'Sigiloso';
    case '0':
      return 'Não é prioridade';
    case '1':
      return 'É prioridade';
    default:
      return value;
  }
}

function GetFieldName(field) {
  switch (field) {
    case 'id':
      return 'Id';
    case 'internal_id':
      return 'Id interno';
    case 'external_id':
      return 'Id externo';
    case 'manual_id':
      return 'manual_id';
    case 'created_at':
      return 'Criado em';
    case 'reated_externally_at':
      return 'Local criado externamente';
    case 'created_by':
      return 'Criado por';
    case 'updated_by':
      return 'Atualizado por';
    case 'created_at_unity':
      return 'Criado pela unidade';
    case 'updated_at_unity':
      return 'Atualizado pela unidade';
    case 'institution':
      return 'Instituição';
    case 'dispatched_by':
      return 'Emitido por';
    case 'subject':
      return 'Assunto';
    case 'model':
      return 'Modelo';
    case 'nature':
      return 'Natureza';
    case 'format':
      return 'Formato';
    case 'status':
      return 'Status';
    case 'kind':
      return 'Tipo';
    case 'name':
      return 'Nome';
    case 'body':
      return 'Corpo';
    case 'url':
      return 'URL';
    case 'description':
      return 'Descrição';
    case 'specifications':
      return 'Especificações';
    case 'settings':
      return 'Configurações';
    case 'privacy':
      return 'Privacidade';
    case 'priority':
      return 'Prioridade';
    case 'verification_id':
      return 'ID de verificação';
    default:
      return 'CAMPO DESCONHECIDO';
  }
}

function GetMessage(item) {
  if (item.agent === 'DOCUMENT') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
      if (item.action === 'ACCESS') {
        return `Visualizado por ${CapitalizeString(item.created_by_name)}`;
      }
      if (item.action === 'UPDATE') {
        if (item.field === 'model') {
          return `O modelo do processo foi atualizado`;
        }
        if (item.field === 'body') {
          return `O corpo do processo foi atualizado`;
        }
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
    }
    if (item.kind === 'ASSIGNMENT') {
      if (item.action === 'INTERESTED PART') {
        return `Interessado inserido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'SIGNATORY') {
        return `Assinante inserido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa inserida: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor inserido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinado por: ${CapitalizeString(item.assignment_person_name)}`;
      }
    }
    if (item.kind === 'UNASSIGNMENT') {
      if (item.action === 'SIGNATORY') {
        return `Assinante removido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa removida: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor removido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'INTERESTED PART') {
        return `Interessado removido: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinatura cancelada por: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
    }
    if (item.kind === 'PROCESSING') {
      if (item.action === 'SEND') {
        return `Tramitado de ${CapitalizeString(
          item.created_by_name
        )} para ${CapitalizeString(item.receiver_person_name)}`;
        // return `Tramitado`;
      }
    }
    if (item.kind === 'ATTACHMENT') {
      if (item.action === 'CREATE') {
        return `Anexo "${CapitalizeString(
          item.attached_document_name
        )}" inserido`;
        // return `Tramitado para: ${CapitalizeString(item.receiver_person_name)}`;
      }
      if (item.action === 'UPDATE') {
        return `Anexo "${CapitalizeString(
          item.attached_document_name
        )}" atualizado`;
      }
    }
  }
  if (item.agent === 'DOCUMENT ATTACHMENT') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        if (item.field === 'name') {
          return `Anexo inserido: ${item.attachment_name}`;
        }
      }
      if (item.action === 'UPDATE') {
        return `Anexo inativado: ${item.attachment_name}`;
      }
    }
  }
}

export default function PDFViewer({
  fatherDocument,
  attachments,
  timelineItems,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // console.log('carregou')
    setLoading(false);
  }

  function Next() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function Previous() {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  useEffect(() => {
    async function getPDF(obj) {
      const content = obj;
      const params = {
        operation: ['SIGAM', 'PDF', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data: {
          document: {
            pageMargins: [20, 70, 20, 60],
            header: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  image: `§€${DecodeURI(
                    fatherDocument.institution_avatar || ''
                  )}€§`,
                  fit: [50, 50],
                },
                {
                  fontSize: 10,
                  alignment: 'center',
                  text: [
                    `${fatherDocument.institution_name || ''} \n`,
                    `${fatherDocument.created_at_unity_name || ''} \n`,
                  ],
                },
                {
                  fontSize: 8,
                  alignment: 'right',
                  text: `NDoc: ${fatherDocument.internal_id}`,
                },
              ],
            },
            content,
            footer: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  // margin: [10, 0, 10],
                  width: '10%',
                  qr: `https://${System.hostname.SIGAMApp}/verificacao?documento=${fatherDocument.internal_id}`,
                  fit: 50,
                },
                {
                  width: '80%',
                  fontSize: 8,
                  text: [
                    `Conforme Lei nº 14.063, de 23 de setembro de 2020 \n Verifique a autenticidade deste documento em https://app.sigam.net.br/verificacao informando o código: ${fatherDocument.verification_id} \n`,
                  ],
                },
                {
                  margin: [10, 0, 10],
                  image: `§€https://app.milanote.com/media/p/images/1RJAk61TKNy54H/eao/Variante1_PNG%20%281%29.png?v=2&elementId=1RJAk61TKNy54H€§`,
                  width: 50,
                  heigth: 40,
                },
              ],
            },
          },
        },
      };
      // console.log('params ', params)
      const response = await SocialMeAPI(params);
      // console.log('response ', response)
      if (response.success) {
        setPDF(response.body.file);
      }
      setLoading(false);
    }

    async function getPDFMakeObj() {
      const html = htmlToPdfmake(fatherDocument.body);
      const content = [
        {
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20],
          text: fatherDocument.name,
        },
      ];
      for (let i = 0; i < html.length; i++) {
        content.push(html[i]);
      }

      if (timelineItems && timelineItems.length > 0) {
        const body = [];
        for (let i = 0; i < timelineItems.length; i++) {
          if (
            timelineItems[i].action !== 'CREATE' &&
            timelineItems[i].kind !== 'DIRECT CHANGE'
          ) {
            const newItem = [];
            newItem.push({
              image: `§€https://app.milanote.com/media/p/images/1RJAsj1TKNy54I/jTM/assinatura.jpg?v=2&elementId=1RJAsj1TKNy54I€§`,
              width: 80,
              heigth: 40,
            });
            newItem.push({
              text: [
                {
                  text: `Documento assinado eletronicamente por `,
                  fontSize: 14,
                },
                {
                  text: `${CapitalizeString(timelineItems[i].person_name)}`,
                  bold: true,
                  fontSize: 16,
                },
                {
                  text: `, em ${DateInBrazilianFormat(
                    timelineItems[i].signature_created_at
                  )}, às ${HourInBrazilianFormat(
                    timelineItems[i].signature_created_at
                  )}, conforme horário oficial de Brasília.`,
                  fontSize: 14,
                },
              ],
            });

            body.push(newItem);
          }
        }
        content.push({
          // pageBreak: 'before',
          table: {
            widths: [80, '*'],
            body,
          },
        });
        content.push({
          text: `As assinaturas acima estão de acordo com a lei federal de nº 14.063 de 23 de Setembro de 2020`,
          fontSize: 12,
          alignment: 'center',
          margin: [0, 20, 0, 0],
        });
      }

      for (let j = 0; j < attachments.length; j++) {
        content.push({
          stack: [
            { text: '', pageBreak: 'after' },
            {
              text: `ANEXO ${j + 1} do documento ${fatherDocument.internal_id}`,
              fontSize: 20,
              bold: true,
              margin: [0, 0, 0, 20],
            },
            { text: 'Sobre o documento de origem', fontSize: 14, bold: true },
            {
              text: [
                { text: 'Nome: ', bold: true },
                { text: `${fatherDocument.name}` },
              ],
            },
            {
              text: [
                { text: 'Criação: ', bold: true },
                { text: `${DateInBrazilianFormat(fatherDocument.created_at)}` },
              ],
            },
            {
              text: [
                { text: 'Instituição: ', bold: true },
                { text: `${fatherDocument.institution_name}` },
              ],
            },
            {
              text: [
                { text: 'Unidade: ', bold: true },
                { text: `${fatherDocument.created_at_unity_name}` },
              ],
            },
            {
              text: [
                { text: 'Id interno: ', bold: true },
                { text: `${fatherDocument.internal_id}` },
              ],
            },
            {
              text: [
                { text: 'Código de verificação: ', bold: true },
                { text: `${fatherDocument.verification_id}` },
              ],
            },
            {
              text: 'Sobre o anexo',
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 0],
            },
            {
              text: [
                { text: 'Nome: ', bold: true },
                { text: `${attachments[j].name}` },
              ],
            },
            {
              text: [
                { text: 'Anexado em: ', bold: true },
                { text: `${DateInBrazilianFormat(attachments[j].created_at)}` },
              ],
            },
            {
              text: [
                { text: 'Id interno: ', bold: true },
                { text: `${attachments[j].internal_id}` },
              ],
            },
            /* 
                        {
                            text: [
                                { text: 'Ordem: ', bold: true },
                                { text: `${`Anexo ${j + 1} de um total de ${attachments.length} anexos`}` }
                            ]
                        }
                        */
          ],
        });
        /*
                content.push({
                    text: `ANEXO ${j+1}`,
                    pageBreak: 'before'
                },)
                */
      }
      /* 
            if(timelineItems && timelineItems.length > 0){
                const body = []
                const header = ['Data', 'Hora', 'Detalhes']
                body.push(header)
                for (let i = 0; i < timelineItems.length; i++) {
                    if (timelineItems[i].action !== 'CREATE' && timelineItems[i].kind !== 'DIRECT CHANGE') {
                        const newItem = []
                        newItem.push(DateInBrazilianFormat(timelineItems[i].created_at))
                        newItem.push(HourInBrazilianFormat(timelineItems[i].created_at))
                        newItem.push(GetMessage(timelineItems[i]))
                        body.push(newItem)
                    }
                   
                }
                content.push(
                    {
                        table: {
                            widths: [70, 40, '*'],
                            body
                        }
                    }
                )
            }
            */

      /*
             if(timelineItems && timelineItems.length > 0){
                 const body = []
                 const header = ['Data', 'Hora', 'Assinante']
                 body.push(header)
                 for (let i = 0; i < timelineItems.length; i++) {
                     if (timelineItems[i].action !== 'CREATE' && timelineItems[i].kind !== 'DIRECT CHANGE') {
                         const newItem = []
                         newItem.push(DateInBrazilianFormat(timelineItems[i].created_at))
                         newItem.push(HourInBrazilianFormat(timelineItems[i].created_at))
                         newItem.push( { text: `${CapitalizeString(timelineItems[i].assignment_person_name)}`, bold: true, fontSize: 14 })
                         body.push(newItem)
                     }
                    
                 }
                 content.push({
                     pageBreak: 'before',
                     image: `§€https://media.milanote.com/p/images/1NkmEW1LMOSt27/kJQ/assinatura.png€§`,
                     width: 80,
                     heigth: 40,
                     alignment: 'center'
                 })
                 content.push( { text: `Assinam esse documento:`, fontSize: 12, alignment: 'center', margin: [ 0, 0, 0, 20 ] })
                 content.push(
                     {
                         layout: 'lightHorizontalLines',
                         table: {
                             widths: [70, 40, '*'],
                             body
                         }
                     }
                 )
                 content.push( { text: `As assinaturas acima estão de acordo com a lei federal de nº 14.063 de 23 de Setembro de 2020`, fontSize: 12, alignment: 'center', margin: [ 0, 20, 0, 0 ] })
             }
             */

      getPDF(content);
    }

    if (fatherDocument && timelineItems) {
      setLoading(true);
      setPDF();
      setPageNumber(1);
      getPDFMakeObj();
    }
  }, [fatherDocument, timelineItems]);

  return (
    <div>
      {loading ? (
        <Button loading type="primary" disabled>
          Carregando
        </Button>
      ) : (
        <a href={pdf} target="_blank" rel="noopener noreferrer">
          <Button icon={<PrinterOutlined />} type="primary">
            Imprimir Documento
          </Button>
        </a>
      )}
    </div>
  );
}
