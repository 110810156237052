import React, { useContext } from 'react';
// Libraries
import { Layout, Row, Col } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
// Own Components
import Nav from './components/Nav';
import TrainingDrawer from './components/TrainingDrawer';
import MobileDrawer from './components/MobileDrawer';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

const { Header, Content } = Layout;

const videosDicas = [
  {
    label: 'MEU PRIMEIRO ACESSO NO IMPACTO',
    src: 'https://www.youtube.com/embed/aX3KHXRT5RE',
  },
  {
    label: 'DOCUMENTOS - CRIANDO UM DOCUMENTO COM ANEXO',
    src: 'https://www.youtube.com/embed/oliEftumCWI',
  },
  {
    label: 'DOCUMENTOS - CRIANDO DOCUMENTO EXTERNO',
    src: 'https://www.youtube.com/embed/mUFUw5SOGyQ',
  },
  {
    label: 'DOCUMENTOS - CRIANDO DOCUMENTO EM BRANCO',
    src: 'https://www.youtube.com/embed/Gype7-FdkRw',
  },
  {
    label: 'DOCUMENTOS - CRIANDO DOCUMENTOS BASEADO EM MODELO',
    src: 'https://www.youtube.com/embed/GopxHR6Qzxw',
  },
  {
    label: 'PROCESSO - ANEXANDO UM DOCUMENTO EM UM NOVO PROCESSO',
    src: 'https://www.youtube.com/embed/pwuLNEBYvqA',
  },
  {
    label: 'PROCESSO - ANEXANDO UM DOCUMENTO EM PROCESSO EXISTENTE',
    src: 'https://www.youtube.com/embed/6bHx9KffWyA',
  },
  {
    label: 'PROCESSO - CRIANDO UM PROCESSO',
    src: 'https://www.youtube.com/embed/kKeA3nx_jr0',
  },
];

export default function Help() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  function VideoContainer({ video }) {
    return (
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '20px',
        }}
      >
        <Title
          level={5}
          style={{ color: theme.color.primary, textAling: 'center' }}
        >
          {video.label}
        </Title>
        <iframe
          style={{ border: '2px solid', borderColor: theme.color.primary }}
          width={isMobile ? '300' : '560'}
          height={isMobile ? '300' : '315'}
          src={video.src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Col>
    );
  }

  return (
    <Layout>
      <Header style={{ padding: 0 }}>
        <Nav />
      </Header>
      <Content
        style={{
          minHeight: `calc(100vh - 64px)`,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ margin: '10px 30px' }}>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Title
              level={3}
              style={{ color: theme.color.primary, textAling: 'center' }}
            >
              Manuais do IMPACTO
            </Title>
          </div>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: '5px' }}
            >
              <a
                href="https://hubme-IMPACTO.s3.us-east-1.amazonaws.com/assets/Manual%20-%20Configura%C3%A7%C3%B5es%20de%20Administrador.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Card
                  style={{
                    border: '2px solid',
                    borderColor: theme.color.primary,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <ReadOutlined style={{ fontSize: '24pt' }} />
                    <Text>Manual do Usuário:</Text>
                    <Text>Configurações do Administrador</Text>
                  </div>
                </Card>
              </a>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: '5px' }}
            >
              <a
                href="https://hubme-IMPACTO.s3.us-east-1.amazonaws.com/assets/Manual%20-%20Processos%20e%20Documentos.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <Card
                  style={{
                    border: '2px solid',
                    borderColor: theme.color.primary,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <ReadOutlined style={{ fontSize: '24pt' }} />
                    <Text>Manual do Usuário:</Text>
                    <Text>Processos e Documentos</Text>
                  </div>
                </Card>
              </a>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: '5px' }}
            >
              <TrainingDrawer />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ padding: '5px' }}
            >
              <MobileDrawer />
            </Col>
          </Row>
        </div>
        <div style={{ margin: '10px 30px' }}>
          <div
            style={
              isMobile
                ? {}
                : {
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }
            }
          >
            <Title
              level={3}
              style={{ color: theme.color.primary, textAling: 'center' }}
            >
              Dicas IMPACTO
            </Title>
          </div>

          <Row>
            {videosDicas.map((video, index) => (
              <VideoContainer key={index} video={video} />
            ))}
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
