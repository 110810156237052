import React, { useContext } from 'react';
// Libraries
import { LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Button from '~/components/button';

export default function Nav() {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        backgroundColor: theme.color.primary,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 10px',
      }}
    >
      <div>
        <img style={{ height: 50, marginTop: 20 }} src="/logo.png" alt="..." />
      </div>
      <div>
        <Link to="/">
          <Button icon={<LoginOutlined />}>Acessar o sistema</Button>
        </Link>
      </div>
    </div>
  );
}
