import React, { useEffect, useState } from 'react';
import htmlToPdfmake from 'html-to-pdfmake';
import { useParams } from 'react-router-dom';
// Utils
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Button from '~/components/button';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DecodeURI } from '~/utils/Transformations';
import System from '~/environments/System';
import Loading from '~/components/loading/';

export default function PDFViewer({ fatherDocument, document }) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // console.log('carregou')
    setLoading(false);
  }

  function Next() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function Previous() {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  useEffect(() => {
    async function getPDF(obj) {
      const content = obj;
      const params = {
        operation: ['SIGAM', 'PDF', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data: {
          document: {
            pageMargins: [20, 70, 20, 60],
            header: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  image: `§€${DecodeURI(
                    fatherDocument.institution_avatar || ''
                  )}€§`,
                  fit: [50, 50],
                },
                {
                  fontSize: 10,
                  alignment: 'center',
                  text: [
                    `${fatherDocument.institution_name || ''} \n`,
                    `${document.created_at_unity_name || ''} \n`,
                  ],
                },
                {
                  fontSize: 8,
                  alignment: 'right',
                  text: `NDoc: ${document.document_internal_id}`,
                },
              ],
            },
            /*
                        header: {
                            margin: [10, 20, 10, 10],
                            columns: [
                                {
                                    width: '20%',
                                    image: DecodeURI(fatherDocument.institution_avatar || ''),
                                },
                                {
                                    width: '50%',
                                    margin: [10, 0, 0, 0],
                                    fontSize: 16,
                                    alignment: 'center',
                                    text: [`${fatherDocument.institution_name || ''}\n`,`${fatherDocument.created_at_unity_name || ''}\n`]
                                },
                                {
                                     width: '30%',
                                    text: `NDoc: ${document.internal_id}`
                                }
                            ]
                        },
                        */
            content,
            /*
                        content: {
                            "text": "My title",
                            "nodeName": "H1",
                            "fontSize": 24,
                            "bold": true,
                            "marginBottom": 5,
                            "style": [
                                "html-h1",
                                "html-div"
                            ]
                        },
                        */
            footer: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  // margin: [10, 0, 10],
                  width: '10%',
                  qr: `https://${System.hostname.SIGAMApp}/verificacao?documento=${document.document_internal_id}`,
                  fit: 50,
                },
                {
                  width: '80%',
                  fontSize: 8,
                  text: [
                    `Conforme Lei nº 14.063, de 23 de setembro de 2020 \n','Verifique a autenticidade deste documento em https://app.sigam.net.br/verificacao informando o código: ${document.document_verification_id} \n`,
                  ],
                },
                {
                  margin: [10, 0, 10],
                  image: `§€https://app.milanote.com/media/p/images/1RJAk61TKNy54H/eao/Variante1_PNG%20%281%29.png?v=2&elementId=1RJAk61TKNy54H€§`,
                  width: 50,
                  heigth: 40,
                },
              ],
            },
            /*
                        footer: {
                            margin: [0,0,10,0],
                            columns: [
                            {
                                margin: [10, 0, 10],
                                width: '10%',
                                qr: `https://${System.hostname.SIGAMApp}/verificacao?documento=${document.internal_id}`,
                                fit: 50
                            },
                            {
                              width: '80%',
                              fontSize: 8,
                              text: ['Conforme Lei nº 14.063, de 23 de setembro de 2020 \n','Verifique a autenticidade deste documento em https://app.sigam.net.br/verificacao informando o código: KBL5TNG \n']
                            },
                            {
                                margin: [10, 0, 10],
                                width: '10%',
                                image: 'https://media.milanote.com/p/images/1L5DiR1bzJOVfr/R9B/SIGAM-02.jpg',
                            }
                          ],
                        }
                        */
          },
        },
      };
      // console.log('params ', params)
      const response = await SocialMeAPI(params);
      // console.log('response ', response)
      if (response.success) {
        setPDF(response.body.file);
      }
      setLoading(false);
    }
    async function getPDFMakeObj() {
      const html = htmlToPdfmake(document.body);
      const content = [
        {
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20],
          text: document.name,
        },
      ];
      for (let i = 0; i < html.length; i++) {
        content.push(html[i]);
      }
      getPDF(content);
    }

    if (fatherDocument) {
      getPDFMakeObj();
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loading text="Criando documento" />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <a href={pdf} target="_blank" rel="noopener noreferrer">
              <Button type="dashed">Imprimir Documento</Button>
            </a>
          </div>
          <div style={{ border: '1px solid black ', padding: 5, margin: 5 }}>
            <Document
              file={{ url: pdf }}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{ workerSrc: 'pdf.worker.js' }}
              loading={<Loading text="Criando documento" />}
            >
              <Page size="A4" pageNumber={pageNumber} />
            </Document>
          </div>
          <div>
            <p>
              Página {pageNumber} de {numPages}
            </p>
          </div>
          <div>
            <Button onClick={() => Previous()} style={{ marginRigth: 10 }}>
              Voltar
            </Button>
            <Button onClick={() => Next()} style={{ marginLeft: 10 }}>
              Próxima
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
