import React, { useEffect, useState } from 'react';
import htmlToPdfmake from 'html-to-pdfmake';
import { useParams } from 'react-router-dom';
// Utils
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { PrinterOutlined } from '@ant-design/icons';
import Button from '~/components/button';
import SocialMeAPI from '~/utils/SocialMeAPI';
import { DecodeURI, CapitalizeString } from '~/utils/Transformations';
import System from '~/environments/System';
import Loading from '~/components/loading/';
import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from '~/utils/DateAndTime';

function GetFieldValue(value) {
  switch (value) {
    case 'ACTIVE':
      return 'Ativo';
    case 'INACTIVE':
      return 'Inativo';
    case 'PUBLIC':
      return 'Público';
    case 'RESTRICTED':
      return 'Restrito';
    case 'CONFIDENTIAL':
      return 'Sigiloso';
    case '0':
      return 'Não é prioridade';
    case '1':
      return 'É prioridade';
    default:
      return value;
  }
}

function GetFieldName(field) {
  switch (field) {
    case 'id':
      return 'Id';
    case 'internal_id':
      return 'Id interno';
    case 'external_id':
      return 'Id externo';
    case 'manual_id':
      return 'manual_id';
    case 'created_at':
      return 'Criado em';
    case 'reated_externally_at':
      return 'Local criado externamente';
    case 'created_by':
      return 'Criado por';
    case 'updated_by':
      return 'Atualizado por';
    case 'created_at_unity':
      return 'Criado pela unidade';
    case 'updated_at_unity':
      return 'Atualizado pela unidade';
    case 'institution':
      return 'Instituição';
    case 'dispatched_by':
      return 'Emitido por';
    case 'subject':
      return 'Assunto';
    case 'model':
      return 'Modelo';
    case 'nature':
      return 'Natureza';
    case 'format':
      return 'Formato';
    case 'status':
      return 'Status';
    case 'kind':
      return 'Tipo';
    case 'name':
      return 'Nome';
    case 'body':
      return 'Corpo';
    case 'url':
      return 'URL';
    case 'description':
      return 'Descrição';
    case 'specifications':
      return 'Especificações';
    case 'settings':
      return 'Configurações';
    case 'privacy':
      return 'Privacidade';
    case 'priority':
      return 'Prioridade';
    case 'verification_id':
      return 'ID de verificação';
    default:
      return 'CAMPO DESCONHECIDO';
  }
}

function GetMessage(item) {
  if (item.agent === 'PROCESS') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
      if (item.action === 'ACCESS') {
        return `Visualizado por ${CapitalizeString(item.created_by_name)}`;
      }
      if (item.action === 'UPDATE') {
        if (item.field === 'model') {
          return `O modelo do processo foi atualizado por ${item.created_by_name}`;
        }
        if (item.field === 'body') {
          return `O corpo do processo foi atualizado por ${item.created_by_name}`;
        }
        if (item.field === 'status') {
          return '';
        }
        return `O campo "${GetFieldName(
          item.field
        )}" recebeu o valor "${GetFieldValue(item.value)}"`;
      }
    }
    if (item.kind === 'ASSIGNMENT') {
      if (item.action === 'INTERESTED PART') {
        return `Interessado inserido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'SIGNATORY') {
        return `Assinante inserido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa inserida por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor inserido por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinado por: ${CapitalizeString(item.created_by_name)}`;
      }
    }
    if (item.kind === 'UNASSIGNMENT') {
      if (item.action === 'SIGNATORY') {
        return `Assinante removido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'PRIVATE ACCESS') {
        return `Pessoa removida por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'CO-OWNER') {
        return `Editor removido por ${item.created_by_name}: ${CapitalizeString(
          item.assignment_person_name
        )}`;
      }
      if (item.action === 'INTERESTED PART') {
        return `Interessado removido por ${
          item.created_by_name
        }: ${CapitalizeString(item.assignment_person_name)}`;
      }
      if (item.action === 'LOGIN-PASSWORD') {
        return `Assinatura cancelada por: ${CapitalizeString(
          item.created_by_name
        )}`;
      }
    }
    if (item.kind === 'PROCESSING') {
      if (item.action === 'SEND') {
        return `Tramitado de ${CapitalizeString(
          item.created_by_name
        )} para ${CapitalizeString(item.receiver_person_name)}`;
        // return `Tramitado`;
      }
    }
    if (item.kind === 'ATTACHMENT') {
      if (item.action === 'CREATE') {
        return `Anexo inserido por ${item.created_by_name}: ${CapitalizeString(
          item.attached_document_name
        )}`;
        // return `Tramitado para: ${CapitalizeString(item.receiver_person_name)}`;
      }
      if (item.action === 'UPDATE') {
        return `Anexo atualizado por ${
          item.created_by_name
        }: ${CapitalizeString(item.attached_document_name)}`;
      }
    }
  }
  if (item.agent === 'DOCUMENT ATTACHMENT') {
    if (item.kind === 'DIRECT CHANGE') {
      if (item.action === 'CREATE') {
        if (item.field === 'name') {
          return `Anexo inserido por ${item.created_by_name}: ${item.attachment_name}`;
        }
      }
      if (item.action === 'UPDATE') {
        return `Anexo inativado por ${item.created_by_name}: ${item.attachment_name}`;
      }
    }
  }
}

function GetTitle(item) {
  if (item.action === 'CREATE') {
    if (item.kind === 'ATTACHMENT') {
      return `Lista de anexos atualizada por ${item.created_by_name}:`;
    }
    if (item.agent === 'PROCESS') {
      return `Processo criado por ${item.created_by_name}:`;
    }
    return `Movimentação desconhecida`;
  }
  if (item.action === 'UPDATE') {
    if (item.field === 'body') {
      return `Processo atualizado por ${item.created_by_name}, corpo foi alterado.`;
    }
    if (item.field === 'status') {
      if (item.value === 'INACTIVE') {
        return `O processo foi arquivado por ${item.created_by_name}`;
      }
      return `O processo foi reaberto por ${item.created_by_name}`;
    }
    if (item.agent === 'DOCUMENT ATTACHMENT') {
      return `Lista de anexos atualizada por ${item.created_by_name}:`;
    }
    return `Processo atualizado por ${item.created_by_name}:`;
  }
  if (item.action === 'ACCESS') {
    return `Processo visualizado por ${item.created_by_name}:`;
  }
  if (item.action === 'PRIVATE ACCESS') {
    return `Lista restrita atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'CO-OWNER') {
    return `Lista de editores atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'INTERESTED PART') {
    return `Lista de interessados atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'SIGNATORY') {
    return `Lista de assinantes atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'LOGIN-PASSWORD') {
    return `Lista de assinaturas atualizada por ${item.created_by_name}:`;
  }
  if (item.action === 'SEND') {
    return `Processo tramitado por ${item.created_by_name}`;
  }
}

export default function PDFViewer({
  fatherProcess,
  attachments,
  timelineItems,
}) {
  const { institutionId, organizationUnitId } = useParams();
  const [loading, setLoading] = useState(true);
  const [pdf, setPDF] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // console.log('carregou')
    setLoading(false);
  }

  function Next() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function Previous() {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  useEffect(() => {
    async function getPDF(obj) {
      const content = obj;
      const params = {
        operation: ['SIGAM', 'PDF', 'Get'],
        urlParams: {
          institutionId,
          organizationUnitId,
        },
        data: {
          document: {
            pageMargins: [20, 70, 20, 60],
            header: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  image: `§€${DecodeURI(
                    fatherProcess.institution_avatar || ''
                  )}€§`,
                  fit: [50, 50],
                },
                {
                  fontSize: 10,
                  alignment: 'center',
                  text: [
                    `${fatherProcess.institution_name || ''} \n`,
                    `${fatherProcess.created_at_unity_name || ''} \n`,
                  ],
                },
                {
                  fontSize: 8,
                  alignment: 'right',
                  text: `NPro: ${fatherProcess.internal_id}`,
                },
              ],
            },
            content,
            footer: {
              margin: [10, 10, 10, 10],
              columns: [
                {
                  // margin: [10, 0, 10],
                  width: '10%',
                  qr: `https://${System.hostname.SIGAMApp}/verificacao?documento=${fatherProcess.internal_id}`,
                  fit: 50,
                },
                {
                  width: '80%',
                  fontSize: 8,
                  text: [
                    `Conforme Lei nº 14.063, de 23 de setembro de 2020 \n','Verifique a autenticidade deste documento em https://app.sigam.net.br/verificacao informando o código: ${fatherProcess.verification_id} \n`,
                  ],
                },
                {
                  margin: [10, 0, 10],
                  image: `§€https://app.milanote.com/media/p/images/1RJAk61TKNy54H/eao/Variante1_PNG%20%281%29.png?v=2&elementId=1RJAk61TKNy54H€§`,
                  width: 50,
                  heigth: 40,
                },
              ],
            },
          },
        },
      };
      // console.log('params ', params)
      const response = await SocialMeAPI(params);
      // console.log('response ', response)
      if (response.success) {
        setPDF(response.body.file);
      }
      setLoading(false);
    }

    async function getPDFMakeObj() {
      const html = htmlToPdfmake(fatherProcess.body);
      const content = [
        {
          text: [
            { text: 'Nome: ', bold: true },
            { text: `${fatherProcess.name}` },
          ],
        },
        {
          text: [
            { text: 'Criação: ', bold: true },
            { text: `${DateInBrazilianFormat(fatherProcess.created_at)}` },
          ],
        },
        {
          text: [
            { text: 'Instituição: ', bold: true },
            { text: `${fatherProcess.institution_name}` },
          ],
        },
        {
          text: [
            { text: 'Unidade: ', bold: true },
            { text: `${fatherProcess.created_at_unity_name}` },
          ],
        },
        {
          text: [
            { text: 'Id interno: ', bold: true },
            { text: `${fatherProcess.internal_id}` },
          ],
        },
      ];
      const body = [];
      const header = ['Data', 'Hora', 'Ação', 'Detalhes'];
      body.push(header);
      const create = [];
      create.push(DateInBrazilianFormat(timelineItems[0].created_at));
      create.push(HourInBrazilianFormat(timelineItems[0].created_at));
      create.push('Processo Criado');
      create.push(
        `Processo criado por ${CapitalizeString(
          timelineItems[0].created_by_name
        )}`
      );
      body.push(create);
      for (let i = 0; i < timelineItems.length; i++) {
        if (
          timelineItems[i].action !== 'CREATE' &&
          timelineItems[i].kind !== 'DIRECT CHANGE'
        ) {
          const newItem = [];
          newItem.push(DateInBrazilianFormat(timelineItems[i].created_at));
          newItem.push(HourInBrazilianFormat(timelineItems[i].created_at));
          newItem.push(GetTitle(timelineItems[i]));
          newItem.push(GetMessage(timelineItems[i]));
          body.push(newItem);
        }
      }
      // console.log('body -> ', body)
      content.push({
        table: {
          widths: [70, 40, 100, '*'],
          body,
        },
      });

      for (let i = 0; i < html.length; i++) {
        content.push(html[i]);
      }
      for (let j = 0; j < attachments.length; j++) {
        content.push({
          stack: [
            { text: '', pageBreak: 'after' },
            {
              text: `ANEXO ${j + 1}`,
              fontSize: 20,
              bold: true,
              margin: [0, 0, 0, 20],
            },
            { text: 'Sobre o processo de origem', fontSize: 14, bold: true },
            {
              text: [
                { text: 'Nome: ', bold: true },
                { text: `${fatherProcess.name}` },
              ],
            },
            {
              text: [
                { text: 'Criação: ', bold: true },
                { text: `${DateInBrazilianFormat(fatherProcess.created_at)}` },
              ],
            },
            {
              text: [
                { text: 'Instituição: ', bold: true },
                { text: `${fatherProcess.institution_name}` },
              ],
            },
            {
              text: [
                { text: 'Unidade: ', bold: true },
                { text: `${fatherProcess.created_at_unity_name}` },
              ],
            },
            {
              text: [
                { text: 'Id interno: ', bold: true },
                { text: `${fatherProcess.internal_id}` },
              ],
            },
            {
              text: 'Sobre o anexo',
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 0],
            },
            {
              text: [
                { text: 'Nome: ', bold: true },
                { text: `${attachments[j].name}` },
              ],
            },
            {
              text: [
                { text: 'Anexado em: ', bold: true },
                { text: `${DateInBrazilianFormat(attachments[j].created_at)}` },
              ],
            },
            {
              text: [
                { text: 'Ordem: ', bold: true },
                {
                  text: `${`Anexo ${j + 1} de um total de ${
                    attachments.length
                  } anexos`}`,
                },
              ],
            },
          ],
        });
        /*
                content.push({
                    text: `ANEXO ${j+1}`,
                    pageBreak: 'before'
                },)
                */
      }
      getPDF(content);
    }

    if (fatherProcess && timelineItems) {
      setLoading(true);
      setPDF();
      setPageNumber(1);
      getPDFMakeObj();
    }
  }, [fatherProcess, timelineItems]);

  return (
    <div>
      {loading ? (
        <Button loading type="primary" disabled>
          Carregando
        </Button>
      ) : (
        <div>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <Button icon={<PrinterOutlined />} type="primary">
              Imprimir Processo
            </Button>
          </a>
        </div>
      )}
    </div>
  );
}
